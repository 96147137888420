var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var B7,D7,F7,Ata,H7,I7,Bta;B7=function(a){if("string"===typeof a)return $CLJS.dh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.dh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.Hl(a));};$CLJS.C7=function(a){return $CLJS.Ua(a)?$CLJS.Ly(a,$CLJS.H([$CLJS.gi,!0])):a};
D7=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=D7[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=D7._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.setting",a);}return a};$CLJS.E7=function(a,b){return D7($CLJS.b_(a),b)};F7=function(a,b,c){a=(b-a)/c;if(null!=a)a=Math.ceil(a);else throw $CLJS.Uh("Unexpected Null passed to ceil",new $CLJS.h(null,1,[$CLJS.vi,"ceil"],null));a=$CLJS.Rd(a);return 1<a?a:1};
Ata=function(a,b,c){var d=B7(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.yz(d))/Math.log(10)));return $CLJS.Me(function(f){f*=e;return f>=d?f:null},$CLJS.fsa)};
$CLJS.G7=function(a,b,c){var d=$CLJS.e5(b);if($CLJS.n(d)){var e=$CLJS.CD.h(d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "num-bins":e=$CLJS.Bz(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SM,$CLJS.Mi,$CLJS.gj],null));if($CLJS.n(e)){a=$CLJS.O(e);e=$CLJS.J.g(a,$CLJS.ni);var f=$CLJS.J.g(a,$CLJS.tj);a=$CLJS.O(d);a=$CLJS.J.g(a,$CLJS.jD);a=Ata(e,f,a);return new $CLJS.h(null,3,[$CLJS.BD,a,$CLJS.GK,c,$CLJS.JK,c+a],null)}return null;case "bin-width":return a=$CLJS.O(d),a=$CLJS.J.g(a,$CLJS.BD),new $CLJS.h(null,
3,[$CLJS.BD,a,$CLJS.GK,c,$CLJS.JK,c+a],null);case "default":e=$CLJS.Bz(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SM,$CLJS.Mi,$CLJS.gj],null));if($CLJS.n(e)){d=$CLJS.O(e);e=$CLJS.J.g(d,$CLJS.ni);f=$CLJS.J.g(d,$CLJS.tj);a:switch(d=e,e=f,f=$CLJS.Oh instanceof $CLJS.M?$CLJS.Oh.S:null,f){case "num-bins":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,2,[$CLJS.jD,null,$CLJS.BD,B7(((e-d)/0).toFixed(5))],null)],null);break a;case "bin-width":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BD,new $CLJS.h(null,
2,[$CLJS.BD,null,$CLJS.jD,F7(d,e,null)],null)],null);break a;case "default":$CLJS.SZ(b)?(a=$CLJS.E7(a,$CLJS.esa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BD,new $CLJS.h(null,2,[$CLJS.BD,a,$CLJS.jD,F7(d,e,a)],null)],null)):(a=$CLJS.E7(a,$CLJS.dsa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,2,[$CLJS.jD,a,$CLJS.BD,B7(((e-d)/a).toFixed(5))],null)],null));break a;default:throw Error(["No matching clause: ",$CLJS.p.h(f)].join(""));}if($CLJS.n(a))return $CLJS.I(a,0,null),a=$CLJS.I(a,1,null),
a=$CLJS.O(a),a=$CLJS.J.g(a,$CLJS.BD),new $CLJS.h(null,3,[$CLJS.BD,a,$CLJS.GK,c,$CLJS.JK,c+a],null)}return null;default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}}else return null};H7=function(a){var b=$CLJS.dz.h($CLJS.Y0(a));b=b.h?b.h($CLJS.z7):b.call(null,$CLJS.z7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.od($CLJS.r4.g(b,-1))&&$CLJS.od($CLJS.O5.g(b,-1))){if(b=$CLJS.Xj.j(b,$CLJS.qK,$CLJS.nd),!$CLJS.y($CLJS.qK.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
I7=function(a,b){var c=H7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.yU(b,new $CLJS.h(null,2,[$CLJS.IV,$CLJS.A7,$CLJS.NV,$CLJS.zta],null));var d=$CLJS.V_,e=d.j,f=a;var k=a;var l=$CLJS.H1(k,-1);k=$CLJS.n(l)?$CLJS.EV(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.T1.v(a,-2,$CLJS.pV(b),d);if($CLJS.n(b))a=$CLJS.Xj.j(a,$CLJS.qK,$CLJS.nd);else return null}};
Bta=function(a,b,c){var d=function(){var e=$CLJS.n($CLJS.e5(b))?function(){var f=$CLJS.M5(b,null);if(null!=c){var k=$CLJS.G7(a,b,c);if($CLJS.n(k)){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.GK);l=$CLJS.J.g(l,$CLJS.JK);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.L5(f,k),$CLJS.K5(f,l)],null)}return null}return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.F1($CLJS.KE,new $CLJS.P(null,1,5,$CLJS.Q,[f],null))],null)}():null;if($CLJS.n(e))return e;e=function(){var f=$CLJS.A7.h(b);return $CLJS.n(f)?$CLJS.e3(b,f):b}();return new $CLJS.P(null,
1,5,$CLJS.Q,[(0,$CLJS.X2)(e,c)],null)}();return $CLJS.bb(function(e,f){return $CLJS.Y2.j(e,-1,f)},a,d)};
$CLJS.J7=function(a,b){b=$CLJS.O(b);var c=$CLJS.J.g(b,$CLJS.AK),d=$CLJS.J.g(b,$CLJS.HK);b=$CLJS.PV.l(a,-1,$CLJS.Nj,$CLJS.H([$CLJS.ZE,$CLJS.YE,$CLJS.NQ,$CLJS.jR,$CLJS.RD]));b=$CLJS.bb(function(f,k){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.wi);l=$CLJS.J.g(l,$CLJS.$i);return Bta(f,k,l)},b,function(){return function l(k){return new $CLJS.ie(null,function(){for(var m=k;;)if(m=$CLJS.y(m)){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);$CLJS.Tj.g($CLJS.MJ.h($CLJS.wi.h(A)),
$CLJS.jK)&&v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}v=$CLJS.z(m);if($CLJS.Tj.g($CLJS.MJ.h($CLJS.wi.h(v)),$CLJS.jK))return $CLJS.ae(v,l($CLJS.Hc(m)));m=$CLJS.Hc(m)}else return null},null,null)}(d)}());var e=function(){var f=$CLJS.ed(c);return $CLJS.n(f)?$CLJS.rG(function(k){return $CLJS.E.g($CLJS.n5(k),f)},$CLJS.r4.g(a,-1)):null}();return $CLJS.bb(function(f,k){return $CLJS.Y2.j(f,-1,k)},b,$CLJS.n(e)?function(){var f=$CLJS.z(e);switch(f instanceof
$CLJS.M?f.S:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ed(e)],null);case "metric":return $CLJS.Z2.g($CLJS.R.j($CLJS.WX.h((0,$CLJS.BX)($CLJS.E1($CLJS.C7(new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.FE,$CLJS.FE,$CLJS.C7($CLJS.FY.h($CLJS.O3(a,$CLJS.ed(e))))],null)),$CLJS.Mi,$CLJS.FE))),$CLJS.UO,$CLJS.UO.h(a)),-1);default:return null}}():null)};$CLJS.W5.m(null,$CLJS.T6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.N6);b=$CLJS.J.g(b,$CLJS.NY);return new $CLJS.h(null,3,[$CLJS.Mi,$CLJS.T6,$CLJS.N6,a,$CLJS.NY,b],null)});
$CLJS.V5.m(null,$CLJS.T6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){return $CLJS.J7(H7(c),$CLJS.Xj.j(e,$CLJS.HK,function(f){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){var u=t;if($CLJS.vd(u)){var v=$CLJS.ic(u),x=$CLJS.D(v),A=$CLJS.le(x);return function(){for(var G=0;;)if(G<x){var K=$CLJS.hd(v,
G);$CLJS.pe(A,$CLJS.Xj.j(K,$CLJS.wi,function(){return function(S){return I7(c,S)}}(G,K,v,x,A,u,t)));G+=1}else return!0}()?$CLJS.oe($CLJS.qe(A),m($CLJS.jc(u))):$CLJS.oe($CLJS.qe(A),null)}var C=$CLJS.z(u);return $CLJS.ae($CLJS.Xj.j(C,$CLJS.wi,function(){return function(G){return I7(c,G)}}(C,u,t)),m($CLJS.Hc(u)))}return null}},null,null)}(f)}))}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Hc(c);return b(d,e,f,c)};a.l=b;return a}());