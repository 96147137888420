var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.util.internal.time.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.js");
'use strict';var bpa,cpa,dpa,h0,i0,j0,epa,fpa,gpa,l0,m0,hpa;$CLJS.g0=function(a){var b=$CLJS.dd($CLJS.dh($CLJS.fh(["(.*?)",$CLJS.mH($CLJS.H([$CLJS.sH])),"$"].join("")),a));return $CLJS.n(b)?b:a};bpa=function(a,b){return a.isSame(b,"day")};cpa=function(a,b){return a.isSame(b,"month")};dpa=function(a,b){return a.isSame(b,"year")};
h0=function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.Dk);d=$CLJS.J.g(d,$CLJS.d0);return $CLJS.n($CLJS.dh(b,a))?(b=$CLJS.NH.parseZone(a,d,!0),$CLJS.n(b.isValid())?new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null):null):null},$CLJS.apa)};i0=function(a){return a instanceof Date?$CLJS.NH.utc(a):a};j0=function(a,b,c){b="string"===typeof b?$CLJS.z(h0(b)):i0(b);return("string"===typeof c?$CLJS.z(h0(c)):i0(c)).diff(b,$CLJS.Xg(a))};
epa=function(a,b){return j0.j?j0.j($CLJS.Kk,a,b):j0.call(null,$CLJS.Kk,a,b)};fpa=function(a,b){if("string"===typeof a)return a;a=i0(a);var c=function(){if($CLJS.Dz(b,$CLJS.Fk))return $CLJS.a0;if($CLJS.Dz(b,$CLJS.Lk))return $CLJS.$_;if($CLJS.Dz(b,$CLJS.yk))return $CLJS.e0;if($CLJS.Dz(b,$CLJS.Zi))return $CLJS.b0;if($CLJS.Dz(b,$CLJS.vk))return $CLJS.c0;throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}();return $CLJS.f0.h(new $CLJS.P(null,2,5,$CLJS.Q,[a,c],null))};
$CLJS.k0=function(a){if($CLJS.n($CLJS.NH.isMoment(a)))return a;if("string"===typeof a)return(0,$CLJS.NH)($CLJS.g0(a),gpa);throw $CLJS.Uh("Unknown input to coerce-to-time; expecting a string",new $CLJS.h(null,1,[$CLJS.$i,a],null));};gpa=["HH:mm:ss.SSS[Z]","HH:mm:ss.SSS","HH:mm:ss","HH:mm"];l0=function l0(a,b){if("string"===typeof a){var d=h0(a);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);b=l0.g?l0.g(a,b):l0.call(null,a,b);return $CLJS.f0.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null))}a=i0(a);return a.startOf($CLJS.Xg(b))};
m0=function m0(a,b,c){if("string"===typeof a){var e=h0(a);a=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);b=m0.j?m0.j(a,b,c):m0.call(null,a,b,c);return $CLJS.f0.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,e],null))}a=i0(a);return a.add(c,$CLJS.Xg(b))};hpa=new $CLJS.M(null,"local","local",-1497766724);$CLJS.n0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g($CLJS.nH,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.o0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g($CLJS.Z_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.ipa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(bpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.jpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(cpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.kpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(dpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.lpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(epa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.mpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(j0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.p0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(l0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.npa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(m0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.opa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(fpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.q0=function q0(a){switch(arguments.length){case 1:return q0.h(arguments[0]);case 2:return q0.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.shared.util.time.coerce_to_timestamp",$CLJS.q0);$CLJS.q0.h=function(a){return $CLJS.q0.g(a,$CLJS.N)};
$CLJS.q0.g=function(a,b){b=$CLJS.mk.l($CLJS.H([$CLJS.N,$CLJS.fA(b)]));if(!$CLJS.n($CLJS.Y_(a))){var c=(c="string"===typeof a)?$CLJS.dh(/.*(Z|[+-]\d\d:?\d\d)$/,a):c;a=$CLJS.n(c)?$CLJS.NH.parseZone(a):"string"===typeof a?$CLJS.oH.g(a,b):$CLJS.pH.g(a,b)}return $CLJS.n(hpa.h(b))?a.local():a};$CLJS.q0.A=2;module.exports={coerce_to_timestamp:$CLJS.q0,coerce_to_time:$CLJS.k0};