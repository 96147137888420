var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var NI;$CLJS.LI=function(a){return $CLJS.n($CLJS.rB($CLJS.rd,$CLJS.qd,$CLJS.Yk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.MI=function(a,b){return $CLJS.Me($CLJS.zd,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(K,S,X,T){return function(){for(var da=C;;){var oa=$CLJS.y(da);if(oa){var Oa=oa,rb=$CLJS.z(Oa);if(oa=$CLJS.y(function(Ra,Wa,db,Ka,lb,Ja,bc,Kd){return function Rh(gd){return new $CLJS.ie(null,function(pz,Il,Un,xr,Q_,Lv){return function(){for(;;){var Mv=
$CLJS.y(gd);if(Mv){if($CLJS.vd(Mv)){var Nv=$CLJS.ic(Mv),qz=$CLJS.D(Nv),Ek=$CLJS.le(qz);a:for(var yr=0;;)if(yr<qz){var ht=$CLJS.hd(Nv,yr);ht=$CLJS.E.g(Lv,$CLJS.YD)||$CLJS.E.g(Un,$CLJS.YD)||$CLJS.zE(Lv,ht)&&$CLJS.zE(Un,ht);Ek.add(ht);yr+=1}else{Nv=!0;break a}return Nv?$CLJS.oe($CLJS.qe(Ek),Rh($CLJS.jc(Mv))):$CLJS.oe($CLJS.qe(Ek),null)}Ek=$CLJS.z(Mv);return $CLJS.ae($CLJS.E.g(Lv,$CLJS.YD)||$CLJS.E.g(Un,$CLJS.YD)||$CLJS.zE(Lv,Ek)&&$CLJS.zE(Un,Ek),Rh($CLJS.Hc(Mv)))}return null}}}(Ra,Wa,db,Ka,lb,Ja,bc,
Kd),null,null)}}(da,K,rb,Oa,oa,S,X,T)($CLJS.jE)))return $CLJS.bf.g(oa,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.LI($CLJS.MD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.LI($CLJS.MD(a)))}())};
NI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.Ne.j($CLJS.vE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);return $CLJS.Le($CLJS.il,
$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.MI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.OI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.PI=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.QI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var RI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lr,$CLJS.er],null)),SI=null,TI=0,UI=0;;)if(UI<TI){var Lia=SI.X(null,UI);$CLJS.yE(Lia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.ni,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)],null)],null)]));UI+=1}else{var VI=$CLJS.y(RI);if(VI){var WI=VI;if($CLJS.vd(WI)){var XI=$CLJS.ic(WI),Mia=$CLJS.jc(WI),
Nia=XI,Oia=$CLJS.D(XI);RI=Mia;SI=Nia;TI=Oia}else{var Pia=$CLJS.z(WI);$CLJS.yE(Pia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.ni,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)],null)],null)]));RI=$CLJS.B(WI);SI=null;TI=0}UI=0}else break}$CLJS.wE($CLJS.gr,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)]));
for(var YI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.zF],null)),ZI=null,$I=0,aJ=0;;)if(aJ<$I){var Qia=ZI.X(null,aJ);$CLJS.yE(Qia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.ni,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)],null)],null)]));aJ+=1}else{var bJ=$CLJS.y(YI);if(bJ){var cJ=bJ;if($CLJS.vd(cJ)){var dJ=$CLJS.ic(cJ),Ria=$CLJS.jc(cJ),
Sia=dJ,Tia=$CLJS.D(dJ);YI=Ria;ZI=Sia;$I=Tia}else{var Uia=$CLJS.z(cJ);$CLJS.yE(Uia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.ni,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)],null)],null)]));YI=$CLJS.B(cJ);ZI=null;$I=0}aJ=0}else break}
for(var eJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Eq,$CLJS.Gq,$CLJS.Aq,$CLJS.Cq],null)),fJ=null,gJ=0,hJ=0;;)if(hJ<gJ){var Via=fJ.X(null,hJ);$CLJS.uE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Via,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)]));hJ+=1}else{var iJ=$CLJS.y(eJ);if(iJ){var jJ=iJ;if($CLJS.vd(jJ)){var kJ=$CLJS.ic(jJ),Wia=$CLJS.jc(jJ),Xia=kJ,Yia=$CLJS.D(kJ);eJ=Wia;fJ=Xia;gJ=Yia}else{var Zia=
$CLJS.z(jJ);$CLJS.uE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Zia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)]));eJ=$CLJS.B(jJ);fJ=null;gJ=0}hJ=0}else break}
$CLJS.uE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.LE,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)]));
$CLJS.uE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.RE,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)]));
for(var lJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KE,$CLJS.WE],null)),mJ=null,nJ=0,oJ=0;;)if(oJ<nJ){var $ia=mJ.X(null,oJ);$CLJS.wE($ia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)]));oJ+=1}else{var pJ=$CLJS.y(lJ);if(pJ){var qJ=pJ;if($CLJS.vd(qJ)){var rJ=$CLJS.ic(qJ),aja=$CLJS.jc(qJ),bja=rJ,cja=$CLJS.D(rJ);lJ=aja;mJ=bja;nJ=cja}else{var dja=$CLJS.z(qJ);$CLJS.wE(dja,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)]));lJ=$CLJS.B(qJ);
mJ=null;nJ=0}oJ=0}else break}
for(var sJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ME,$CLJS.TE],null)),tJ=null,uJ=0,vJ=0;;)if(vJ<uJ){var eja=tJ.X(null,vJ);$CLJS.wE(eja,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)]));vJ+=1}else{var wJ=$CLJS.y(sJ);if(wJ){var xJ=wJ;if($CLJS.vd(xJ)){var yJ=$CLJS.ic(xJ),fja=$CLJS.jc(xJ),gja=yJ,hja=$CLJS.D(yJ);sJ=fja;tJ=gja;uJ=hja}else{var ija=$CLJS.z(xJ);$CLJS.wE(ija,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)]));sJ=$CLJS.B(xJ);
tJ=null;uJ=0}vJ=0}else break}
for(var zJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null)],null),AJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.aF,$CLJS.uF,$CLJS.iF,$CLJS.bF],null)),BJ=null,CJ=0,DJ=0;;)if(DJ<CJ){var EJ=BJ.X(null,DJ);$CLJS.IF.v(EJ,$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,EJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.wD,zJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null));DJ+=1}else{var FJ=$CLJS.y(AJ);if(FJ){var GJ=FJ;if($CLJS.vd(GJ)){var HJ=$CLJS.ic(GJ),jja=$CLJS.jc(GJ),kja=HJ,lja=$CLJS.D(HJ);AJ=jja;BJ=kja;CJ=lja}else{var IJ=$CLJS.z(GJ);$CLJS.IF.v(IJ,$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,IJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.wD,zJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.fE],null)],null));AJ=$CLJS.B(GJ);BJ=null;CJ=0}DJ=0}else break}
$CLJS.IF.v($CLJS.eB,$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.eB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.wD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gk,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Qu,$CLJS.Vw,$CLJS.QE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.iE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dD],null)],null));$CLJS.IF.v($CLJS.HE,$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.HE],null),$CLJS.wD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.iD,$CLJS.qD],null)],null));
$CLJS.Y($CLJS.OI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.PI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.br,$CLJS.Hq,$CLJS.zF,$CLJS.RE,$CLJS.LE,$CLJS.Eq,$CLJS.Aq,$CLJS.Gq,$CLJS.Cq,$CLJS.KE,$CLJS.WE,$CLJS.ME,$CLJS.TE,$CLJS.iF,$CLJS.bF,$CLJS.aF,$CLJS.uF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QI,$CLJS.Ai],null)],null));